import request from '@/utils/request'

//短信验证码
export function sendSms(param) {
    return request({
        url: `/api/sendCode`,
        method: 'post',
        data: param,
        headers: 2

    })
}

//提交用户信息
export function submitUserInfo(param) {
    return request({
        url: `/api/apply/form/add`,
        method: 'post',
        data: param,
        headers: 2

    })
}

// 查询轮播图
export function rotations(param) {
    return request({
        url: `/api/rotations`,
        method: 'post',
        data: param,
        headers: 2

    })
}




